
























































































import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext } from "vue-router";
import { State } from "@/app/shared/components/drag-&-drop/drag-&-drop-data-transfer";
import { Debounce } from "@/app/shared/utils/debounce";

import Drag from "@/app/shared/components/drag-&-drop/drag.vue";
import Drop from "@/app/shared/components/drag-&-drop/drop.vue";
import DragAnchor from "@/app/shared/components/drag-&-drop/drag-anchor.vue";
import Tree from "@/app/shared/components/tree/tree.vue";

import DateWUtc from "@/app/shared/utils/date-w-utc";
import Date from "@/app/shared/utils/date";

import Structure from "@/app/domains/document/models/structure.model";
import Node from "@/app/shared/components/tree/models/node.model";
import Document from "@/app/domains/document/models/document.model";
import TreeDropDataModel from "@/app/shared/components/tree/models/tree-drop.model";
import ProjectModel from "@/app/shared/models/project.model";

interface ProjectNode extends Node {
  projectId?: number;
  creationDate?: string;
  projectDocuments?: [];
  documentContents?: [];
  children: Array<ProjectNode>;
  changed?: boolean;
  projects: Array<{ id: number; name: string }>;
}

@Component({
  components: {
    Tree,
    DragAnchor,
    Drag,
    Drop,
  },

  filters: {
    DateWUtc,
    Date,
  },

  async beforeRouteUpdate(to, from, next): Promise<void> {
    const instance = this as Project;
    await instance.preventRedirect(next);
    if (instance.somethingHasChanged) return;
    instance.getProjectStructure(to.params.settingsProject);
    instance.getUnusedDocuments(to.params.settingsProject);
  },

  beforeRouteLeave(to, from, next): void {
    (this as Project).preventRedirect(next);
  },
})
export default class Project extends Vue {
  private treeData: Array<ProjectNode> = [];
  private unusedDocuments: Array<ProjectNode> = [];
  private somethingHasChanged = false;

  $refs!: {
    "project-tree": Tree;
  };

  //#region filter
  private debounceFilter = new Debounce((instance: Project, value) => {
    instance.filterValue = value;
  }, 200);
  private filterValueOnView = "";
  private filterValue = "";

  private get viewFilterValue() {
    return this.filterValueOnView;
  }

  private set viewFilterValue(value: string) {
    this.filterValueOnView = value;
    this.debounceFilter.invoke(this, this.filterValueOnView);
  }
  //#endregion

  private get settingsProject(): string {
    return this.$route.params.settingsProject;
  }

  private get filteredDocuments(): Array<ProjectNode> {
    if (this.filterValue) {
      const lowerFilterValue = this.filterValue.toLowerCase();
      return this.unusedDocuments.filter((d) =>
        d.documentName.toLowerCase().includes(lowerFilterValue)
      );
    }
    return this.unusedDocuments;
  }

  private nodeClassFunc(node: ProjectNode): string {
    if (node.changed) return "changed-node";
    return "";
  }

  private async preventRedirect(next: NavigationGuardNext<Vue>): Promise<void> {
    try {
      if (this.somethingHasChanged) {
        await this.$confirm(
          "All changes will not be saved. Are you sure you want to leave this page?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        this.somethingHasChanged = false;
      }
      next();
    } catch (error) {
      next(false);
      return;
    }

    if (this.somethingHasChanged) {
      return;
    }
  }

  private somethingChanged(): void {
    this.somethingHasChanged = true;
  }

  private mouseDragEnd(state: State<ProjectNode, Array<ProjectNode>>): void {
    this.treeData.push(state.dragData);
    state.dragData.changed = true;
    this.unusedDocuments = this.unusedDocuments.filter(
      (d) => d.id != state.dragData.id
    );
    this.somethingChanged();
  }

  private documentsMouseDragEnd(
    state: State<ProjectNode, Array<ProjectNode>>
  ): void {
    const node = state.dragData;
    const parent = node.parentId
      ? this.$refs["project-tree"].treeDictionary[node.parentId].data
      : null;
    const parentNodes = parent ? parent.children : this.treeData;
    const index = parentNodes.findIndex((c) => c.id === node.id);
    if (index >= 0) {
      parentNodes.splice(index, 1);
      this.unusedDocuments.unshift(node);
    }
    // remove all children nodes
    if (node.children.length) this.moveNodesToDocuments(node.children);
    node.parentId = null;
    node.projectId = undefined;
    node.children = [];
    node.changed = true;
    this.somethingChanged();
  }

  private drop(config: TreeDropDataModel): void {
    const index = this.unusedDocuments.findIndex(
      (t) => t.id === config.state.dragData.id
    );
    if (index >= 0) {
      const [removeDocumentNode] = this.unusedDocuments.splice(index, 1);
      removeDocumentNode.changed = true;
    }
    config.applyCallback();
    this.somethingChanged();
  }

  private moveNodesToDocuments(nodes: Array<ProjectNode>): void {
    nodes.forEach((node) => {
      this.unusedDocuments.unshift(node);
      if (node.children.length) this.moveNodesToDocuments(node.children);
      node.parentId = null;
      node.projectId = undefined;
      node.children = [];
      node.changed = true;
    });
  }

  private convertProjectNodesToStructure(
    nodes: Array<ProjectNode>
  ): Array<Structure> {
    return nodes.map((n) => {
      // reset node state
      Vue.set(n, "changed", false);
      let structure: Structure = {
        id: n.id,
        projectId: parseInt(this.settingsProject),
        parentId: n.parentId,
        documentName: n.documentName,
        children: this.convertProjectNodesToStructure(n.children),
      };
      return structure;
    });
  }

  private convertDocumentsToProjectNodes(
    documents: Array<Document>
  ): Array<ProjectNode> {
    return documents.map((d) => {
      const document: ProjectNode = {
        id: d.id || 0,
        projectName: "",
        parentId: null,
        children: [],
        documentName: d.name,
        creationDate: d.creationDate,
        projects: d.projects,
      };
      return document;
    });
  }

  private async getProjectStructure(projectId: string): Promise<void> {
    try {
      this.treeData = (
        await this.$store.dispatch("documentModule/getStructure", projectId)
      ).data;
    } catch (error) {
      console.error(error);
    }
  }

  private async getUnusedDocuments(projectId: string): Promise<void> {
    try {
      const documents: Array<Document> = (
        await this.$store.dispatch(
          "settingsModule/getUnusedDocuments",
          projectId
        )
      ).data;
      this.unusedDocuments = this.convertDocumentsToProjectNodes(documents);
    } catch (error) {
      console.error(error);
    }
  }

  private async removeProject(): Promise<void> {
    try {
      await this.$confirm(
        "This will permanently delete the project. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      );
    } catch (error) {
      return;
    }

    try {
      const projectId = parseInt(this.settingsProject);
      const projects = this.$store.getters[
        "projectsModule/projects"
      ] as Array<ProjectModel>;

      await this.$store.dispatch("settingsModule/removeProject", projectId);

      this.$store.commit(
        "projectsModule/setProjects",
        projects.filter((p: ProjectModel) => p.id !== projectId)
      );

      if (projects.length && projects[0].id) {
        // set selected project on settings page
        this.$router.push({
          name: "settings-project-selected",
          params: { settingsProject: projects[0].id?.toString() },
        });

        // set selected project if deleted project was selected on navigation select before
        if (
          this.$store.getters["projectsModule/getSelectedProject"].id ===
          projectId
        ) {
          this.$store.commit("projectsModule/setSelectedProject", projects[0]);
        }
      } else {
        this.$router.push({
          name: "settings",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async submit(): Promise<void> {
    try {
      await this.$store.dispatch("settingsModule/modifyStructure", {
        structure: this.convertProjectNodesToStructure(this.treeData),
        projectId: this.settingsProject,
      });
      // reset documents list state
      this.unusedDocuments.forEach((d) => Vue.set(d, "changed", false));
      this.$message({
        message: "Project updated successfully",
        type: "success",
      });
      this.somethingHasChanged = false;
    } catch (error) {
      console.error(error);
    }
  }

  created(): void {
    if (this.settingsProject) {
      this.getProjectStructure(this.settingsProject);
      this.getUnusedDocuments(this.settingsProject);
    }
  }
}
